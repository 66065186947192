<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'term-of-payment',
};

</script>
